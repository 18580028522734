export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: "fa-solid fa-house-user",
  },
  {
    title: "Quienes somos",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info",
  },
  {
    title: "Servicios",
    url: "/services ",
    cName: "nav-links",
    icon: "fa-solid fa-briefcase",
  },
  {
    title: "Contactanos",
    url: "/Contact",
    cName: "nav-links", 
    icon: "fa-solid fa-address-book",
  },
  {/*  {
    title: "Sign Up",
    url: "/signup",
    cName: "nav-links-mobile",
    
  }*/
  },
];