import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Servicio from "../assets/25.jpg";
import Footer from "../components/Footer";
import Trip from "../components/Trip";
import Byp from "../components/Byp";




function Services() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={Servicio}
        title="Nuestros Servicios"
        btnClass="hide"
      />
      <Trip />

    <Byp/>
     
      <Footer />
    </>
  );
}

export default Services;
