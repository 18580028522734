import "./TripStyle.css";
import TripData from "./TripData";
import t1 from "../assets/traduccion.jpg";
import t2 from "../assets/apostillado.jpg";
import t3 from "../assets/cartas.jpg";
import t4 from "../assets/notary.jpg";

function Trip(){
	return (
    <div className="trip">
      <h1></h1>
      <p></p>
      <div className="tripcard">
        <TripData
          image={t4}
          heading="Notaria de Louisiana"
          text="Un Notario puede redactar, preparar y otorgar declaraciones juradas, reconocimientos y actas auténticas."
        />
        <TripData
          image={t1}
          heading="Traducciones"
          text="Realizamos traducciones de INGLÉS al ESPAÑOL /ESPAÑOL AL INGLÉS"
        />

        <TripData
          image={t2}
          heading="Apostillados"
          text="Verificamos las firmas, sellos o timbres de documentos como órdenes judiciales, contratos, 
           certificados del registro civil, actas de matrimonio, 
           hasta diplomas educativos, entre otros."
        />
        <TripData
          image={t3}
          heading="Elaboracion de Cartas"
          text="Elaboramos todo tipo de cartas, que se acomoden al requerimiento
          del cliente."
        />
      </div>
    </div>
  );
}
export default Trip;