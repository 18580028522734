import Navbar from "../components/Navbar";
import about from "../assets/about.jpg";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import Questions from "../components/Questions";

function About() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={about}
        title="Acerca de Nosotros"
        btnClass="hide"
        
      />
    
      <AboutUs/>
      <Footer/>
    </>
  ); 
}

export default About;
