import "./FooterStyles.css"

const Footer = () =>{
	return (
    <div className="footer">
      <div className="top">
        <div>
          <h1 className="footer-logo">Slidell Tax Care</h1>
          <h4 className="horarioss">Nuestros horarios</h4>
          <p>Lunes a Viernes de 9:00am hasta las 5:00pm</p>
          <p>Sabados desde las 10:00am hasta las 3:00pm </p>
          <p>
            Direccion :
            <a href="https://www.google.com/maps/dir//SLIDELL+TAX+CARE+INC/@30.284989,-89.8565061,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889de7a6d4e07801:0xa308bdad574a74ca!2m2!1d-89.7741048!2d30.2850148?entry=ttu">
               636 Gause Blvd STE 100, Slidell, LA 70458
            </a>
          </p>{" "}
          <a href="tel:+19857071164">
            <i class="fa-solid fa-square-phone"></i>Telefono 985-707-1164
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/profile.php?id=100063736234301">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
        </div>
      </div>

      <div className="bottom">
        {/* de este modo podemos poner algunas divisiones en el final de footer 
        <div>
          <h4>Project</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>
        <div>
          <h4>Referencias</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>
        <div>
          <h4>Virtudes</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>
        <div>
          <h4>Diseno</h4>
          <a href="/">Uno</a>
          <a href="/">dos</a>
          <a href="/">tres</a>
          <a href="/">cuatro</a>
        </div>*/}
        <div>
          
          <h6>Design by @sirvaras 2024</h6>
          <h6>
            {" "}
            <a href="http://publixpc.com/">www.publixpc.com</a>
            
          </h6>
        </div>
      </div>
      
    </div>
  );
}

export default Footer;