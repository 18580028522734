import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Profile1 from "../components/Profile1";
import fondo1 from "../components/kelly.jpg";
import Footer from "../components/Footer";
import Sercolumna from "../components/Sercolumna";







function Home() {
  return (
    <>
      <Navbar />

      <Hero
        cName="hero"
        heroImg={fondo1}
        title="Reliza los Taxes con Nosotros"
        text="Somos tu mejor opción"
        buttonText="Reserva tu Cita"
        url="tel:+19857071164"
        btnClass="show"
      />

      <Profile1 />

      <h1>RESEÑA DE NUESTROS CLIENTES</h1>
      <Sercolumna />

      <Footer />
    </>
  );
}

export default Home;
