import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import "../components/ContactFormStyles.css";

export const Contactform = () => {
  const form = useRef();

  const mostrarAlerta = () => {
    swal({
      title: "Mensaje",
      text: "Tu mensaje fue enviado correctamente",
      icon: "success",
      button: "Aceptar",
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_cher122",
        "template_b4szho9",
        form.current,
        "rwH9Gs7LhspOAGc6v"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="from-container" id="contact">
      <h1>Contactanos</h1>
      <form ref={form} onSubmit={sendEmail}>
        <label>
          <font color="white">Name</font>
        </label>
        <input type="text" name="user_name" placeholder="Escribe Tu nombre" />
        <label>
          <font color="white">Email</font>
        </label>
        <input type="email" name="user_email" placeholder="Escribe tu correo" />
        <label>
          <font color="white">Message</font>{" "}
        </label>
        <textarea name="message" placeholder="Escribe tu comentario aqui" />
        <input type="submit" value="Send" onClick={() => mostrarAlerta()} />
      </form>
    </div>
  );
};
export default Contactform;
