import "./AboutUsStyles.css";
import Card from "./Card";


function AboutUs () {
	return (
    <div className="about-container">
<Card/>

    </div>
  );
}

export default AboutUs