import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import contactanos from "../assets/contactanos.png";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import "../components/ContactFormStyles.css"

function Contact (){
	return (
    	<div id='contact'>
				 <Navbar />
				   <Hero cName="hero-mid" heroImg={contactanos} title="" btnClass="hide" /> 
	
      <ContactForm />
      <Footer />
		</div>
     
    
  );
}

export default Contact;