
import "./ProfileStyle.css";
import { Component } from "react";

class DestinationData extends Component {

render(){

return (
  <div className={this.props.className}>
    <div className="des-text">
      <h2>{this.props.heading}</h2>
      <b>{this.props.text}</b>
      {this.props.text2}

      
           {this.props.text3}
      

      <p>{this.props.text4}</p>
      <p>{this.props.text5}</p>
      <p>{this.props.text6}</p>
    </div>

    <div className="image">
      <img src={this.props.img1} alt="img" />
      <img src={this.props.img2} alt="img" />
    </div>
  </div>
);

}

}

export default DestinationData;