import React from 'react';
import "./FooterStyles.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow
} from 'mdb-react-ui-kit';

export default function App() {
  return (
    
    <MDBRow>
      
      <MDBCol xl={4} lg={6} className='mb-4'>
        <MDBCard>
         
          <MDBCardBody>
            <div className='d-flex align-items-center'>
              <img
                src='https://mdbootstrap.com/img/new/avatars/8.jpg'
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>yobely lopez<i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></p>
                <p className='text-muted mb-0'>The most amazing and professional service you can get.They are such kind,
                reliable, and patient people that offer the best of their knowledge. I recommend Slidell tax care to everyone 
                I know whether it be for taxes, consults,notary, register companies,bookkeeping,letters, translations, and insurance
                , mannn they have it all and do it all!!!</p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl={4} lg={6} className='mb-4'>
        <MDBCard>
          <MDBCardBody>
            <div className='d-flex align-items-center'>
              <img
                src='https://mdbootstrap.com/img/new/avatars/6.jpg'
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>Victoria Ortego<i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></p>
                <p className='text-muted mb-0'>AMAZING FAST AND EASY!!! All I have to say is WOW.
                 I was a super last minute call, needed documentation for a home purchase and the accountant 
                 jumped on it ASAP and had me filled and out in 30 minutes. Amazing service, I’ll be coming back to do 
                 my taxes every year. THANK YOU!!!!</p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl={4} lg={6} className='mb-4'>
        <MDBCard>
          <MDBCardBody>
            <div className='d-flex align-items-center'>
              <img
                src='https://mdbootstrap.com/img/new/avatars/7.jpg'
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>Abby Garcia<i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></p>
                <p className='text-muted mb-0'>Every year we go with Norma to do our taxes, she helped and guided us to create 
                our companies and also, to have follow up through Quick Books. She is the best! 
                I totally recommend her. If you have a company that is just starting, call her,
                 she will help you get ready for tax season and also, organize the finances of your business.</p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl={4} lg={6} className='mb-4'>
        <MDBCard>
          <MDBCardBody>
            <div className='d-flex align-items-center'>
              <img
                src='https://mdbootstrap.com/img/new/avatars/9.jpg'
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>coco makli<i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></p>
                <p className='text-muted mb-0'>A very profesional and excellent experience I had with Slidell Tax service. I recommend for all and any help you need with your taxes.</p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl={4} lg={6} className='mb-4'>
        <MDBCard>
          <MDBCardBody>
            <div className='d-flex align-items-center'>
              <img
                src='https://mdbootstrap.com/img/new/avatars/11.jpg'
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>Francisco Aviles<i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></p>
                <p className='text-muted mb-0'>If you want your taxes done right you need to get them done here!</p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl={4} lg={6} className='mb-4'>
        <MDBCard>
          <MDBCardBody>
            <div className='d-flex align-items-center'>
              <img
                src='https://mdbootstrap.com/img/new/avatars/15.jpg'
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>Perla Sanchez<i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i></p>
                <p className='text-muted mb-0'>Excelente atención y servicio lo recomiendo!</p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}