import homeimg from "../assets/w9.png";
import passport from "../assets/taxes.jpg";	
import pawel from "../assets/1040.jpg";
import splash from "../assets/house.jpg";
import "./ProfileStyle.css";
import DestinationData from "./DestinationData";



const Profile1 = () => {
return (
  <div className="destination">
    <h1>Porque es importante declarar Taxes en Estados Unidos?</h1>
    <p></p>

    <DestinationData
      className="first-des"
      heading=""
      text="  Declarar los impuestos es una responsabilidad crucial en Estados Unidos. "
      text2="Cumplir con las leyes fiscales es fundamental para aprovechar oportunidades 
      y mantener una buena situación legal. El sistema fiscal estadounidense, aunque estricto,
       ofrece beneficios directos e indirectos. Los impuestos federales sobre la renta se aplican a los ingresos anuales. 
       Tanto empresas como personas deben presentar sus declaraciones de impuestos. 
       Hacerlo correctamente ayuda a ahorrar dinero y evitar problemas legales y financieros en el futuro."
      text3="Quienes deben declarar impuestos?"
      text4="Para las empresas en Estados Unidos, es obligatorio presentar su declaración de impuestos anualmente.
       En el caso de las personas que vivan en territorio norteamericano, este proceso depende de factores como sus ingresos,
        estado civil, edad no importa su estatus migratorio. En resumen, declarar impuestos es esencial para cumplir con responsabilidades 
        legales, optimizar la carga tributaria, tomar decisiones informadas y contribuir al bienestar social.
         Además, hacerlo correctamente te permite aprovechar beneficios fiscales y deducciones, lo que ayuda a ahorrar dinero 
         y evitar problemas legales y financieros en el futuro."
      text5=""
      img1={homeimg}
      img2={passport}
    />
    {/*
    <DestinationData
      className="first-des"
      heading="APLICACION ITIN"
      text="  Para sacar el ITIN o renovarlo se necesitan los siguientes documentos: "
      text2="1. Pasaporte Vigente."
      text3="2. En caso que no posea pasaporte, una acta de nacimiento emitida 
      recientemente no más de 3 años. Una identificación del país de origen ya sea Cedula, matricula
      consular o licencial."
      text4="3. Si la aplicación es para un menor de edad que no este en la escuela se necesita
      una acta de nacimiento, constancia medica que tenga la dirección firma y sello"
      text5=""
      img1={homeimg}
      img2={passport}
/> {*/}

    <DestinationData
      className="first-des-reverse"
      heading="Ventajas o beneficios de tener un ITIN number"
      text3="  El Número de Identificación Personal del Contribuyente (ITIN) se utiliza para varios propósitos importantes
       en Estados Unidos. Permíteme explicarte algunos de ellos:"
      text4="Cumplimiento de Leyes Fiscales:
      El IRS emite ITIN para ayudar a las personas a cumplir con las leyes fiscales de EE. UU.
      Proporciona un medio eficiente para procesar y contabilizar declaraciones de impuestos y pagos para aquellos que no son
      elegibles para números de Seguro Social."
      text5="Una vez que tengas tu ITIN, puedes sacar cuentas bancarias las cuales te ayudaran en el futuro para que puedas aplicar
       a Tarjetas de crédito y tambien para que puedas crear un crédito; el cual en el futuro te servira para que puedas cumplir metas como por ejemplo acceder
      a un crédito personal, comprar un auto e incluso comprar hasta tu casa."
      text6=""
      img1={pawel}
      img2={splash}
    />
  </div>
);



}

export default Profile1 ;