import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBRow>
      <MDBCol sm='6'>
        <MDBCard>
          <MDBCardBody>
             <MDBBtn>NEGOCIOS Y COMPAÑIAS</MDBBtn>
            <p></p>
            <MDBCardText>
             <p> -	Consulta Taxes de Negocios</p>
			<p>-	Registro de Compañia + EIN </p>
			<p>- Tax ID para Compañia </p>
			<p>-	Renovación de registración </p>
			<p>- IRS verificación de identidad (CARTA + FAX)  </p>
			<p>- Cierre o cambio en Compañias</p>
			<p>- Auditorias Worker's Compensation</p>
      <p>- Contabilidad basica</p>
      <p>- Aplicaciones para Licencias de negocios</p>
      <p>- Renovaciones de licencias de Alcohol o Tabacco</p>
      <p>- Registracion de compañia con Mississippi</p>
      	<p>- Licencia Home Improvements</p>
      <p>- Servicios de seguros: </p>
      <p>General Liability y Workers Compesation</p>
      <p>-Seguros para Vehiculos comerciales Taxis, camionaje y mucho mas</p>
      
            </MDBCardText>
           
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol sm='6'>
        <MDBCard>
          <MDBCardBody>
           <MDBBtn>PERSONAS NATURALES</MDBBtn>
            <MDBCardText>
             
            </MDBCardText>
            <p> - Preparacion de Impuestos</p>
      <p> -	ITIN + TAX Primera Vez-</p>
			<p>- Renovacion ITIN  + COPIA + TAX</p>
			<p>- Renovación de Residencia</p>
			<p>- Aplicación para Ciudadania USA </p>
			<p>- Aplicación USA Pasaporte</p>
      <p>- Fotos tamano pasaporte</p>
      <p>- Ceremonia de Matrimonios</p>
			<p>- LLamadas IRS(Cliente Actual unicamente) </p>
			<p>- Aplicación para Food Stamps </p>
        <p>- Venta de Money Orders</p>
       <p>- Elaboracion de poderes generales, de salida para menores, o de residencia</p>
      <p>- Servicios de seguros:Autos , casa, inundacion y seguro de vida</p>
      
      <br />
      
   <br />
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}