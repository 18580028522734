import React from 'react';
import normayromel from "../assets/normayromel.JPG";
import mision from "../assets/mision.jpg";

export default function App() {
  return (
    <>
		<h1>Nuestra Historia</h1>
      <img src={normayromel} className="rounded-4 shadow-4 float-start m-4"
      alt="" style={{width: "200px"}} />

      <p className="">En Slidell Tax Care , somos un negocio manejado 100% por una familia
        Hispana radicados en la Ciudad de Slidell Louisiana y sirviendo a la
        comunidad por mas de 14 años.</p>
				<p>
        En nuestra oficina, nuestros clientes son atendidos con la mayor
        familiaridad posible para hacer de su experiencia la mejor.
      </p>
      <p>Nos apasiona lo que hacemos y por eso somos la diferencia.</p>

		<img src={mision} className="rounded-4 shadow-4 float-end m-4"
      alt="" style={{width: "200px"}} />
			<p></p>
      <h1>Nuestra Mision</h1>

       <p>
        Ayudar a nuestros clientes, en lo que este a nuestro alcance
        profesional. Nos caracterizamos por ofrecer una variedad de servicios
        profesionales para la comodidad del cliente.
      </p>

			  <h1>Nuestras Metas</h1>
				 <p>
        Manternos actualizados de acuerdo a los cambios del Departamente del
        Tesoro IRS para ayudar a nuestros clientes a obtener el mejor reembolso
        y/o deducción correspondiente.
      </p>
      <p>
        Atendender a nuestros clientes siempre con amabilidad y respeto y sobre
        todo hacerlo sentir especial ya que son la fundación de nuestro negocio.
      </p>

    </>
  );
}